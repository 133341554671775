<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
const axios = require('axios')
export default {
  name: 'App',
  async mounted() {
     if (this.$route.query.i) {
        let {data} = await axios.get(`https://webticketing.wl.tc/ticket/get/ticket?ref=${this.$route.query.i}`)
        this.travels = data.products.map(e => {

            let tmp = JSON.parse(e.name)
            localStorage.setItem("WORLD_TRAVEL_TICKET_LANG", tmp.language);
            if(tmp.language){
              this.$i18n.locale = tmp.language
            }
            switch (tmp.simulation) {
              case "worldTrain":
                this.$router.push({name: "worldTrain",  query: {'ref': this.$route.query.i}})
                break;
              case "worldFlight":
                this.$router.push({name: "worldFlight",  query: {'ref': this.$route.query.i}})
                break;
              case "worldCar":
                this.$router.push({name: "worldCar",  query: {'ref': this.$route.query.i}})
                break;
              case "worldBoat":
                this.$router.push({name: "worldBoat",  query: {'ref': this.$route.query.i}})
                break;
            
              default:
                break;
            }
        })
     }
  },
};
</script>
