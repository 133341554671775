import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/HomeView.vue')
  // },
  {
    path: '/train',
    name: 'worldTrain',
    component: () => import('../views/TrainView.vue')
  },
  {
    path: '/flight',
    name: 'worldFlight',
    component: () => import('../views/FlightView.vue')
  },
  {
    path: '/boat',
    name: 'worldBoat',
    component: () => import('../views/BoatView.vue')
  },
  {
    path: '/car',
    name: 'worldCar',
    component: () => import('../views/CarView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
